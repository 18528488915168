.navigation.sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    z-index: 999;
    border-bottom: 1px solid #cecece;
    -webkit-box-shadow: 0px 0px 191px 0px rgb(0 0 0 / 6%);
    box-shadow: 0px 0px 191px 0px rgb(0 0 0 / 6%);
    -webkit-animation: sticky 2s;
    animation: sticky 2s;
}