.acces-rapide a:hover{
  text-decoration: underline;
    box-decoration-break: clone;
  }

  .acces-rapide {
    padding-bottom: 20px;
    padding-top: 10px;
  }

  .size-text {
    color: white !important;
    font-size: 13px !important;
    padding: 0px 5px !important;
  }

  .nom-footer {
    font-size: 14px !important;
    padding-right: 40px;
  }

  .title-acces{
    border-bottom:2px solid white;
  }