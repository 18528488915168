.farmBackground {
    background-image: url("../assets/images/farm.jpg");
}

.farmingBackground {
    background-image: url("../assets/images/Farming.jpg");
}

.africanFarmersBackground {
    background-image: url("../assets/images/African-farmers.jpg");
}